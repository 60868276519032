/* Global CSS */

*{
    font-family: 'Roboto' !important;
}

.app-bar-desktop-link {
    text-decoration: none;
    color: #c7d5dc;
}

.app-bar-desktop-link:link,
.app-bar-desktop-link:visited {
    color: #c7d5dc;
}

.app-bar-desktop-link:active,
.app-bar-desktop-link:hover {
    color: #ffffff;
}

.link {
    text-decoration: none;
}

.link:link,
.link:visited {
    color: #004360;
}

.link:active,
.link:hover {
    color: #004360;
}


.Chipclick:active,
.Chipclick:focus {
    color: #ececec;
    background-color: #004360;
}

.Chipclick:hover {
    color:#266ec0;
}

